<template>
  <section>

  <div
    class=" card shadow is-animated"
    :class="deadline.past_due ? 'bg-danger' : 'bg-primary'"
    v-show="shouldShowStatus"
  >
    <div class="page-header-content header-elements-md-inline text-left d-flex align-items-center text-uppercase text-md font-bold justify-content-center">
      <div class="page-title d-flex align-items-center text-uppercase py-3 text-md font-bold justify-content-center py-2">
        <h4 class="">
          <span class="font-weight-semibold text-uppercase"
            >{{ deadline.past_due ? "Late" : "Due" }}: </span
          >
          <span class="text-uppercase" > 
            <!-- {{ deadline.ellapsed }} -->
            {{ countdown }}  
          </span>
        </h4>
      </div>
    </div>


  </div>

  <!-- <div  style="font-size: 16px;font-weight: 600;" class="card mt-2 d-flex align-items-center text-uppercase py-3 text-md font-bold justify-content-center">
    time remaining: {{ countdown }}
  </div> -->
  </section>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  props: {
    order: {
      default: {},
    },
  },

  data: () => ({
    countdown: "",
    intervalId: null,
  }),
  async mounted() {
    this.startCountdown();
  },
  methods:{
    startCountdown() {
      this.updateCountdown();
      this.intervalId = setInterval(this.updateCountdown, 1000);
    },
    updateCountdown() {
      const now = new Date();
      let dline = "";
      if (this.userType == this.userTypes.admin) {
        dline = this.order.client_deadline;
      } else if (this.userType == this.userTypes.editor) {
        dline = this.order.editor_deadline.date;
      } else {
        dline = this.order.writer_deadline.date;
      }
      const clientDeadline = new Date(this.order?.writer_deadline?.date); // Parse the deadline string into a Date object

      const timeDifference = clientDeadline - now;



      if (timeDifference <= 0) {
        this.countdown = "Time's up!";
        clearInterval(this.intervalId);
        return;
      }

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      this.countdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;
    }
  },
  computed: {
    ...mapGetters("auth", ["userType", "userTypes"]),
    deadline() {
      let dline = "";
      if (this.userType == this.userTypes.admin) {
        dline = this.order.deadline;
      } else if (this.userType == this.userTypes.editor) {
        dline = this.order.editor_deadline;
      } else {
        dline = this.order.writer_deadline;
      }
      return dline;
    },

    shouldShowStatus() {
      if ("approved completed cancelled".includes(this.order.status_text.toLowerCase())) {
        return false;
      }
      return true;
    },
  },

  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
};
</script>
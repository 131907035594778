<template>
  <div>
    <BaseLoading style="position: fixed; z-index: 3; top: 50%; right: 50%" v-if="is_loading"></BaseLoading>
    <div class="order-info text-left">
      <div class="row">
        <div class="col-12 col-sm-12 table-responsive">
          <div class="order-details-item my-2 order-details-item-paper-detailes">
            <div class="order-details-header d-flex justify-content-between">
                <div class="titles py-2 ">Order details</div>
            </div>                                     
            
          </div>
          <table class="table table-borderless table--custom  text-left " style="font-size: 13px !important">
            <tr>
              <td>Topic</td>
              <td colspan="2"> <span v-html="order.title"></span></td>
            </tr>

            <!-- {{$store.state.user}} -->

            <tr v-if="order.client ">
              <td>Client</td>
              <td class="font-weight-bold">
                <router-link class="text-capitalize" :to="{
                  name: 'ClientProfile',
                  params: { id: order.client.id },
                }">
                  {{ order.client.names || "" }}
                </router-link>
              </td>
              <td>
                <SilentLogin :site="order.site" :client="order.client" />
              </td> 
            </tr>
            <tr>
              <td>Site</td>
              <td>{{ order.site || "" }}</td>
            </tr>

            <tr v-if="order.type == 'programming' || order.type == 'calculations' " >
              <td>Task size  </td>
              <td class="w-100" >

                <div class="StepRow StepRow--s w-100">
                  <div class="rc-validation-representer TaskSizeControl TaskSizeControlForCalculation valid" data-invalid="false">
                    <div  class="rc-radios rc-radios--theme-default vertical-on-small">
                      <div class="radio-button-wrapper ">
                        <button   :class="order.special_order_extras.task_size == '1' ? 'active' : '' " type="button" class="radio-button radio-button--n-5 " tabindex="0" data-testid="radio-button"><div class="button-checkbox"></div>
                          <div class="radio-button__main-content-wrapper py-2 ">
                            <div class="radio-button__title">Extra small</div>
                          </div>
                        </button>
                      </div>

                      <div class="radio-button-wrapper ">
                        <button   :class="order.special_order_extras.task_size == '2' ? 'active' : '' " type="button" class="radio-button radio-button--n-1 " tabindex="-1" data-testid="radio-button"><div class="button-checkbox"></div>
                          <div class="radio-button__main-content-wrapper">
                            <div class="radio-button__title">Small</div>
                          </div>
                        </button>
                      </div>

                      <div class="radio-button-wrapper ">
                        <button  :class="order.special_order_extras.task_size == '3' ? 'active' : '' " type="button" class="radio-button radio-button--n-2" tabindex="0" data-testid="radio-button"><div class="button-checkbox"></div>
                          <div class="radio-button__main-content-wrapper">
                            <div class="radio-button__title">Medium</div>
                          </div>
                        </button>
                      </div>

                      <div class="radio-button-wrapper ">
                        <button   :class="order.special_order_extras.task_size == '4' ? 'active' : '' " type="button" class="radio-button radio-button--n-3" tabindex="0" data-testid="radio-button"><div class="button-checkbox"></div>
                          <div class="radio-button__main-content-wrapper">
                            <div class="radio-button__title">Large</div>
                          </div>
                        </button>
                      </div>
                    </div>


                    <div v-if="order.type == 'calculations' " class="TaskSizeControl__descriptors">
                      <div class="TaskSizeControl__descriptors__example">
                        <div class="TaskSizeControl__descriptors__title">Example:</div>
                        <div v-show="order.special_order_extras.task_size == '1'" class="TaskSizeControl__descriptors__body">up to 3 short practice problems or theoretical questions</div>
                        <div v-show="order.special_order_extras.task_size == '2'" class="TaskSizeControl__descriptors__body">up to 7 short practice problems or theoretical questions</div>
                        <div v-show="order.special_order_extras.task_size == '3'" class="TaskSizeControl__descriptors__body">up to 15 practice problems or theoretical questions</div>
                        <div v-show="order.special_order_extras.task_size == '4'" class="TaskSizeControl__descriptors__body">moderately complex problem analysis project (using applied math methods)</div>
                      </div>
                      <div class="TaskSizeControl__descriptors__deliverables">
                        <div class="TaskSizeControl__descriptors__title">Possible deliverables:</div>
                        <ul v-show="order.special_order_extras.task_size == '1'" class="TaskSizeControl__deliverables">
                          <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--simple-computations">Simple computations</li>
                          <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Short answers to questions</li>
                        </ul>
                        <ul v-show="order.special_order_extras.task_size == '2'" class="TaskSizeControl__deliverables">
                          <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--simple-computations">Simple computations</li>
                          <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--simple-computations">Simple charts and diagrams</li>
                          <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Short answers to questions</li>
                        </ul>
                        <ul v-show="order.special_order_extras.task_size == '3'" class="TaskSizeControl__deliverables">
                          <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--simple-computations">Computations</li>
                          <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--simple-computations">Charts and diagrams</li>
                          <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Detailed explanations</li>
                        </ul>
                        <ul v-show="order.special_order_extras.task_size == '4'" class="TaskSizeControl__deliverables">
                          <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--simple-computations">Computations</li>
                          <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Charts and diagrams</li>
                          <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Detailed report</li>
                          <li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Presentation</li>
                        </ul>
                      </div>
                    </div>

                    <div v-if="order.type == 'programming' " class="TaskSizeControl__descriptors">
                      <div class="TaskSizeControl__descriptors__example">
                        <div class="TaskSizeControl__descriptors__title">Example:</div>
                        <div v-show="order.special_order_extras.task_size == '1'" class="TaskSizeControl__descriptors__body">
                          <span>up to 3 short practice problems</span>
                          <ul class="TaskSizeControl__examples-list">
                            <li>3 functions/outputs</li>
                            <li>class (OOP) with 1-3 simple methods</li>
                            <li>simple binary tree</li>
                            <li>set of short practice tasks</li>
                          </ul>
                        </div>
                        <div v-show="order.special_order_extras.task_size == '2'" class="TaskSizeControl__descriptors__body">
                          <span>up to 7 short practice problems, a simple program with up to 7 functions</span>
                          <ul class="TaskSizeControl__examples-list"><li>small user interface</li><li>data visualization with 3-4 plots</li><li>simple decoder/encode</li><li>set of short practice tasks</li></ul>
                        
                        </div>

                        <div v-show="order.special_order_extras.task_size == '3'" class="TaskSizeControl__descriptors__body">
                          <span>up to 15 practice problems, a small lab report, well-defined project or a landing page</span>
                          <ul class="TaskSizeControl__examples-list"><li>data visualization up to 7 plots</li><li>small lab report</li><li>compiler prototype for a simple language</li><li>database with up to 7 tables</li><li>simple console game</li><li>simple algorithm for 1 scenario</li><li>simple model converted from a drawing</li><li>simple data analysis project on 1 dataset</li></ul>
                        </div>

                        <div v-show="order.special_order_extras.task_size == '4'" class="TaskSizeControl__descriptors__body">
                          <span>large programming tasks or projects, extensive lab report</span>
                          <ul class="TaskSizeControl__examples-list"><li>extensive lab report</li><li>simple game with UI</li><li>web-based product with 1-2 scenarios</li><li>application with 1-2 scenarios</li><li>API</li><li>responsive website of 2-5 pages</li><li>model development from scratch</li><li>data analysis project for a business problem based on 2-3 datasets</li></ul>
                        </div>
                      </div>
                      <div class="TaskSizeControl__descriptors__deliverables">
                        <div class="TaskSizeControl__descriptors__title">Possible deliverables:</div>
                        <ul v-show="order.special_order_extras.task_size == '1'" class="TaskSizeControl__deliverables"><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--source-code">Source code</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--screenshots-of-outputs">Screenshots of outputs</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Short answers to questions</li></ul>

                        <ul v-show="order.special_order_extras.task_size == '2'" class="TaskSizeControl__deliverables"><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--source-code">Source code</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--screenshots-of-outputs">Screenshots of outputs</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Short answers to questions</li></ul>

                        <ul v-show="order.special_order_extras.task_size == '3'" class="TaskSizeControl__deliverables"><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--source-code">Source code</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--screenshots-of-outputs">Screenshots of outputs</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--short-answers-to-questions">Brief report</li></ul>

                        <ul v-show="order.special_order_extras.task_size == '4'" class="TaskSizeControl__deliverables"><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--source-code">Source code</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--screenshots-of-outputs">Screenshots of outputs</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--documentation">Documentation</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--detailed-report">Detailed report</li><li class="TaskSizeControl__deliverables__item TaskSizeControl__deliverables__item--presentation">Presentation</li></ul>

                      </div>
                    </div>



                  </div>
                </div>

              </td>

            </tr>

            <tr v-if="order.type == 'programming'  ">
              <td>Order Extras</td>
              <td class="w-100">
                <div
                  class="preferences row"
                  v-if="ProgrammingTypes.length > 0"
                >
                  <div
                    class="col-12 col-sm-6"
                    v-for="(pref, index) in ProgrammingTypes"
                    :key="index + 'wp'"
                  >
                    <button
                      type="button"
                      class="btn preference border-1"
                      :class="
                        order.special_order_extras.extras.includes(pref.type)  ? 'selected border-primary'
                          : 'default border-muted'
                      "
                      @click="activateExtras(pref)"
                    >
                      <div style="vertical-align: top !important">
                        <div
                          v-show="order.special_order_extras.extras.includes(pref.type)"
                          class="
                            badge badge-primary
                            rounded-circle
                            selected
                          "
                          style="padding: 6px 6px"
                        >
                          <span class="icon-check"></span>
                        </div>
                        <!-- <h4 class="text-success font-weight-bold mb-1">
                          {{ `${pref.cost}` || "" }}
                        </h4> -->
                        <p class="my-1 font-weight-bold">
                          {{ pref.title }}
                          <br />
                        </p>
                        <p
                          class="my-0 text-capitalize"
                          style="
                            white-space: normal;
                            word-wrap: break-word;
                          "
                        >
                          {{ pref.desc }}
                        </p>
                      </div>
                    </button>
                  </div>
                </div>
              </td>
            </tr>


            <tr  v-if="order.type == 'programming'" >
              <td>Programming Language</td>
              <td>: {{ order.special_order_extras.programming_language || "" }}</td>
              <td></td>
            </tr>

            <tr  v-if="order.type == 'programming'" >
              <td>Programming Category</td>
              <td>: {{ order.special_order_extras.programming_category || "" }}</td>
              <td></td>
            </tr>

            <tr  v-if="order.type == 'calculations'" >
              <td>Discipline</td>
              <td>: {{ order.special_order_extras.programming_language || "" }}</td>
              <td></td>
            </tr>

            <tr  v-if="order.type == 'calculations'" >
              <td>Software</td>
              <td>: {{ order.special_order_extras.programming_category || "" }}</td>
              <td></td>
            </tr>


            <tr>
              <td>Number of Page(s)</td>
              <td> {{ order.pages || "" }}</td>
              <td> <button  v-for="(item, index) in additionalPage" :key="index" @click="activeComponent = item " data-toggle="modal"
                data-target="#exampleModal" class=" btn btn-lg btn-outline-success " >
                <!-- <img src="/plus-circle-outline.svg" :alt="item.title"/>  -->
                {{item.title}} </button> </td>
            </tr>
            <tr>
              <td>Number of Slide(s)</td>
              <td> {{ order.slides || "" }}</td>
              <td>
                <button  v-for="(item, index) in additionalSlides" :key="index" @click="activeComponent = item " data-toggle="modal"
                data-target="#exampleModal" class=" btn btn-lg btn-outline-success " >
                <!-- <img src="/plus-circle-outline.svg" :alt="item.title"/>  -->
                {{item.title}} </button> 
              </td>
            </tr>
            <tr>
              <td>Number of Chart(s)</td>
              <td> {{ order.charts || "" }}</td>
              <td>
                <button  v-for="(item, index) in additionalCharts" :key="index" @click="activeComponent = item " data-toggle="modal"
                data-target="#exampleModal" class=" btn btn-lg btn-outline-success " >
                <!-- <img src="/plus-circle-outline.svg" :alt="item.title"/>  -->
                {{item.title}} </button> 
              </td>
            </tr>

            <tr>
              <td>Spacing</td>
              <td> {{ order.spacing_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Language Style</td>
              <td> {{ order.language_style_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Type of Paper</td>
              <td> {{ order.document_type_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Subject Area</td>
              <td> {{ order.subject_area_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Academic Level</td>
              <td> {{ order.academic_level_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Urgency</td>
              <td>
                <span v-if="order.deadline" :class="order.past_due ? 'text-danger' : 'text-success'">: {{
                    order.urgency_text_hrs || ""
                }}</span>
              </td>
              <td>
                <button  v-for="(item, index) in additionalDeadline" :key="index" @click="activeComponent = item " data-toggle="modal"
                data-target="#exampleModal" class=" btn btn-lg btn-outline-success " >
                <!-- <img src="/plus-circle-outline.svg" :alt="item.title"/>  -->
                {{item.title}} </button> 
              </td>
            </tr>
            <tr>
              <td>Client Deadline</td>
              <td :class="
                order.deadline.past_due ? 'text-danger' : 'text-success'
              " class="font-weight-bold">
                 {{ order.deadline.date || "" }}
              </td>
              <td :class="
                order.deadline.past_due ? 'text-danger' : 'text-success'
              " class="font-weight-bold">
                {{ order.deadline.past_due ? "Elapsed" : "" }}
                {{ order.deadline.ellapsed || "" }}
              </td>
            </tr>

            <tr>
              <td>Editor Deadline</td>
              <td :class="
                order.editor_deadline.past_due
                  ? 'text-danger'
                  : 'text-success'
              " class="font-weight-bold">
                {{ order.editor_deadline.date || "" }}
              </td>

              <td :class="
                order.editor_deadline.past_due
                  ? 'text-danger'
                  : 'text-success'
              " class="font-weight-bold">
                {{ order.editor_deadline.past_due ? "Elapsed" : "" }}
                {{ order.editor_deadline.ellapsed || "" }}
              </td>
            </tr>

            <tr>
              <td>Writer Deadline</td>
              <td :class="
                order.writer_deadline.past_due
                  ? 'text-danger'
                  : 'text-success'
              " class="font-weight-bold">
                 {{ order.writer_deadline.date || "" }}
              </td>
              <td :class="
                order.writer_deadline.past_due
                  ? 'text-danger'
                  : 'text-success'
              " class="font-weight-bold">
                {{ order.writer_deadline.past_due ? "Elapsed" : "" }}
                {{ order.writer_deadline.ellapsed || "" }}
              </td>
            </tr>

            <tr>
              <td>Date Ordered</td>
              <td>{{ order.date_placed || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>State</td>
              <td>{{ order.state || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Nursing Speciality</td>
              <td>{{ order.speciality || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Paper Format / Style</td>
              <td>{{ order.style_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Number of sources</td>
              <td>{{ order.no_sources || "0" }}</td>
              <td>
                
              </td>
            </tr>
            <tr>
              <td>Writer Level</td>
              <td>{{ order.writer_level_desc || "0" }}</td>
              <td>
                <!-- <button  v-for="(item, index) in additionalPreferences" :key="index" @click="activeComponent = item " data-toggle="modal"
                data-target="#exampleModal" class=" btn btn-lg btn-outline-success " >
                {{item.title}} </button>  -->
              </td>
            </tr>

            <tr>
              <td>Additional Services</td>
              <td>
                <table class="table table-borderless">
                  <tr v-for="(item, index) in order.additional_services_text" :key="index + 'add'">
                    <td>{{ item[0] }}</td>
                    <hr />
                  </tr>
                </table>
              </td>
              <td>
                <!-- <button  v-for="(item, index) in additionalServices" :key="index" @click="activeComponent = item " data-toggle="modal"
                data-target="#exampleModal" class=" btn btn-lg btn-outline-success " >
                {{item.title}} </button>  -->
              </td>
            </tr>

            <tr>
              <td class="font-weight-bold">Editor Amount</td>
              <td class="font-weight-bold">
                {{ order.editor_cost ? `$${order.editor_cost}` : "" }}
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="font-weight-bold">Writer Amount</td>
              <td class="font-weight-bold">
                {{ order.writer_cost ? `$${order.writer_cost}` : "" }}
              </td>
              <td></td>
            </tr>

            <tr v-for="(extra, index) in order.extras" :key="index + 'oextras'" :class="
              extra.paid !== '0'
                ? 'teal lighten-5 teal--text darken--4'
                : 'error lighten-5 error--text darken--4'
            ">
              <td>{{ extra.name || "" }}</td>
              <td>{{ order.currency_symbol || '$' }}{{ extra.cost || 0 }}</td>
              <td>
                <button v-if="extra.paid !== '0'" class="ml-1 btn btn-sm bg-teal">
                  Paid
                </button>
                <span v-else class="d-flex justify-content-center align-items-center">
                  Unpaid
                  <button @click="deleteExtra(extra.id)" class="mx-1 btn btn-sm btn-danger">
                    Delete
                    <span class="icon-trash"></span>
                  </button>
                </span>
              </td>
            </tr>
            <tr class="teal lighten-5 teal--text darken--4">
              <td class="font-weight-bold">Extras Total</td>
              <td class="font-weight-bold">{{ order.currency_symbol || '$' }}{{ order.extras_total || 0 }}</td>
              <td></td>
            </tr>
            <tr>
              <td class="font-weight-bold">Total Order Cost</td>
              <td class="font-weight-bold">{{ order.currency_symbol || '$' }}{{ order.cost }}</td>
              <td></td>
            </tr>
            <tr class="teal lighten-5 teal--text darken--4">
              <td class="font-weight-bold">Paid</td>
              <td class="font-weight-bold">{{ order.currency_symbol || '$' }}{{ order.total_paid || 0 }}</td>
              <td></td>
            </tr>
            <tr class="error lighten-5 error--text darken--4">
              <td class="font-weight-bold">Balance</td>
              <td class="font-weight-bold">{{ order.currency_symbol || '$' }}{{ order.balance || 0 }}</td>
              <td></td>
            </tr>





            <!-- <tr>
              <td>Paper Details</td>
              <td colspan="2" style="padding: 4px !important">
                <pre style="padding: 4px !important">
                  <span v-html="order.description"></span>
                </pre>
              </td>
            </tr> -->
          </table>
          <div v-if="order.type != 'programming' && order.type != 'calculations' " style="margin-top:30px!important; " class="order-details-item  order-details-item-paper-detailes">
            <div class="order-details-header d-flex justify-content-between">
                <div class="titles py-2 ">Paper details</div>
            </div>  
            <div class="order-details-block">
                <div class="order-option-value order-option-instructions" data-full-text="In ducimus mollitia In ducimus mollitia In ducimus mollitia <span class='see-less-instructions'>See less</span>" data-truncate-text="In ducimus mollitia In ducimus mollitia In ducimus mollitia"> 
                  <pre style="padding: 4px !important">                 
                   <span v-html="order.description"></span>
                  </pre>
                </div>
            </div>                                   
            
          </div>
        </div>



        <div class="col-12 col-sm-4">
          <!-- <div class="order-details-item my-2 order-details-item-paper-detailes">
            <div class="order-details-header d-flex justify-content-between">
                <div class="titles py-2 ">Add Services to Your Paper</div>
            </div>                                     
            
          </div> -->
          <!-- <h6 class="font-weight-bold text-uppercase">
            Add Services to Your Paper
          </h6> -->

          <!-- <div class="row">
            <div v-for="(item, index) in additionalItems" :key="index" class="col-6 py-3">
              <p class="font-weight-bold text-center is-additional" @click="activeComponent = item" data-toggle="modal"
                data-target="#exampleModal">
                <img width="62px" :src="item.icon || ''" />
                <br />
                <span class="text-center"> {{ item.title || "" }}</span>
              </p>
            </div>
          </div> -->
        </div>
      </div>

      <!-- Modal -->
      <BaseModal :title="activeComponent.title">
        <component :is="activeComponent.component"></component>
      </BaseModal>
    </div>
  </div>
</template>


<script>
import pages_png from "../../assets/global_assets/images/ordericons/pages.png";
import slides_png from "../../assets/global_assets/images/ordericons/slides.png";
import charts_png from "../../assets/global_assets/images/ordericons/charts.png";
import deadline_png from "../../assets/global_assets/images/ordericons/deadline.png";
import progressive_png from "../../assets/global_assets/images/ordericons/progressive.png";
import preferences_png from "../../assets/global_assets/images/ordericons/preferences.png";

import AdditionalPages from "../order/modals/AdditionalPages.vue";
import AdditionalServices from "../order/modals/AdditionalServices.vue";
import AdditionalCharts from "../order/modals/AdditionalCharts.vue";
import AdditionalSlides from "../order/modals/AdditionalSlides.vue";
import ShortenDeadline from "../order/modals/ShortenDeadline.vue";
import WriterPreferences from "../order/modals/WriterPreferences.vue";
import { mapActions, mapState } from "vuex";
import SilentLogin from "../SilentLogin.vue";
export default {
  props: {
    order: {
      type: Object,
    },
  },
  computed: {
    ...mapState("auth", ["user"])

  },
  components: {
    AdditionalPages,
    AdditionalServices,
    AdditionalCharts,
    AdditionalSlides,
    ShortenDeadline,
    WriterPreferences,
    SilentLogin,
  },
  data() {
    return {
      is_loading: false,
      activeComponent: {},
      additionalPage: [
        {
          title: "Request Additional Pages",
          icon: pages_png,
          component: "AdditionalPages",
        }
      ],
      ProgrammingTypes:[
       
       {
         title:'Top programming level',
         desc:'We assign top expert for your task',
         type:'1',
       },
       {
         title:'Well-commented code',
         desc:'You get all methods and functions in your code commented.',
         type:'2'
       },       
       
     ], 
      additionalSlides: [
        {
          title: "Request Powerpoint slides",
          icon: slides_png,
          component: "AdditionalSlides",
        }
      ],
      additionalCharts: [
        {
          title: "Request Additional Charts",
          icon: charts_png,
          component: "AdditionalCharts",
        }
      ],
      additionalDeadline: [
        
        {
          title: "Extend Deadline",
          icon: deadline_png,
          component: "ShortenDeadline",
        }
      ],
      additionalPreferences: [
        {
          title: "Update Writer Preferences",
          icon: preferences_png,
          component: "WriterPreferences",
        }
      ],
      additionalServices: [
        {
          title: "Additional Services",
          icon: progressive_png,
          component: "AdditionalServices",
        },
      ],

      // additionalItems: [
      //   {
      //     title: "Additional Pages",
      //     icon: pages_png,
      //     component: "AdditionalPages",
      //   },
      //   {
      //     title: "Powerpoint slides",
      //     icon: slides_png,
      //     component: "AdditionalSlides",
      //   },
      //   {
      //     title: "Charts",
      //     icon: charts_png,
      //     component: "AdditionalCharts",
      //   },
      //   {
      //     title: "Shorten Deadline",
      //     icon: deadline_png,
      //     component: "ShortenDeadline",
      //   },
      //   {
      //     title: "Update Writer Preferences",
      //     icon: preferences_png,
      //     component: "WriterPreferences",
      //   },
      //   {
      //     title: "Additional Services",
      //     icon: progressive_png,
      //     component: "AdditionalServices",
      //   },
      // ],

    };
  },

  methods: {
    ...mapActions("order", ["deleteExtras"]),
    deleteExtra(id) {
      this.is_loading = true;
      this.deleteExtras(id)
        .then((res) => {
          console.log(res);
          // this,_getOrder(this.order.id)
          // alert
          this.$notify({
            title: "Success",
            text: "Order extra deleted successiful!",
          });
        })
        .catch((err) => {
          console.log(err);
          // alert
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table td {
  //   padding: 4px 8px !important;
  max-width: 300px;
}

.is-additional {
  cursor: pointer;

  img {
    margin: 4px;
  }

  &:hover {
    img {
      transform: scale(1.05);
      transition: 0.3s all ease-in-out;
    }
  }
}
</style>

<style lang="scss">
.btn.btn-dark.btn-icon {
  color: #fff !important;
}
</style>